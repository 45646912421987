<template>
    <div class="formBody">
        <v-row align="center" style="width: 100vw">
            <v-row justify="space-around" style="margin: 0 0 0 0">
                <div>
                    <v-dialog
                            v-model="dialog"
                            width="500"
                    >
                        <v-card>
                            <v-card-title
                                    class="headline primary"
                                    primary-title
                            >
                                {{this.$t('dialog.dataFound.headline')}}
                            </v-card-title>

                            <v-card-text class="mt-3">
                                {{this.$t('dialog.dataFound.body')}}
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                        @click="clearRecoverData()"
                                        color="error"
                                        text
                                >
                                    {{this.$t('button.delete')}}
                                </v-btn>
                                <v-btn
                                        @click="recoverData()"
                                        color="primary"
                                >
                                    {{this.$t('button.restore')}}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <h1>Advocard <strong>Business</strong></h1>
                  <v-progress-linear indeterminate color="primary" v-if="isLoading"></v-progress-linear>
                    <v-form
                            :lazy-validation="lazy"
                            class="form"
                            ref="form"
                            v-model="valid"
                    >
                        <v-container style="min-width: 25vw" v-on:input="save()">
                            <v-text-field
                                    :label="inputLabels.policyNumber"
                                    :rules="policyNumberRules"
                                    required
                                    type="number"
                                    v-model="policyNumber"
                            ></v-text-field>
                            <v-text-field
                                    :label="inputLabels.name"
                                    :rules="nameRules"
                                    required
                                    v-model="name"
                            ></v-text-field>
                            <v-textarea
                                    :counter="500"
                                    :label="inputLabels.issue"
                                    auto-grow
                                    v-model="issue"
                            ></v-textarea>
                            <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    min-width="290px"
                                    offset-y
                                    transition="scale-transition"
                                    v-model="consultationDateMenu"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            :label="inputLabels['consultationDate']"
                                            :value="formatDate(consultationDate)"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker @input="saveDate()" color="primary" show-current
                                               v-model="consultationDate"></v-date-picker>
                            </v-menu>
                        </v-container>
                        <v-row
                                class="mb-10"
                                justify="end"
                                md="4"
                        >
                            <v-btn
                                    @click="$router.back()"
                                    class="mr-4"
                                    color="error"
                            >
                                {{this.$t('button.cancel')}}
                            </v-btn>

                            <v-btn
                                    :disabled="blockButtonWhileRequestAndForm()"
                                    @click="validate"
                                    class="mr-4"
                                    color="success"
                            >
                                {{this.$t('button.sent')}}
                            </v-btn>
                        </v-row>
                    </v-form>
                </div>
            </v-row>
        </v-row>
    </div>
</template>

<script>
    import {getAdvocardBusinessURL, requestMode} from '@/configBuilder';
    import i18n from "../../../../plugins/i18n";
    import {sessionHandler} from "@/request/sessionHandler";
    import {showAlert} from "@/utils/alertHandler";
    import dateFormatter from "../../../../utils/dateFormatter";

    export default {
        name: "AdvocardBusiness",
        data: () => ({
            valid: true,
            isLoading: false,
            policyNumber: '',
            name: '',
            issue: '',
            consultationDateMenu: false,
            consultationDate: new Date().toISOString().substr(0, 10),
            lazy: false,
            dialog: false,
        }),

        methods: {
            // disable sent button so prevent multiple entries
            blockButtonWhileRequestAndForm(){
              if (this.valid === false) {return true}
              return this.isLoading === true;
            },

            // Form Data Request
            async sendData() {
                // set Loading true
                this.isLoading = true;

                // init Request Data
                const formData = {
                    "consultationDate": this.consultationDate,
                    "contractNumber": this.policyNumber,
                    "customerName": this.name,
                    "comment": this.issue
                };

                await sessionHandler();
                // send add to Database request
                await this.$http
                    .post(
                        getAdvocardBusinessURL(false),
                        formData
                        , {
                            mode: requestMode(),
                            headers: {
                                'Accept': 'application/json',
                                'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                            },
                        }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            this.clearRecoverData();
                            showAlert(i18n.t('success.form'), "success");
                            this.$router.push("/")
                        } else {
                            showAlert(i18n.t('error.api.undefined') + "AdvocardBusiness-sD1", "error");
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                showAlert(i18n.t('warning.unauthorised'), "warning");
                            } else {
                                showAlert(i18n.t('error.api.undefined') + "AdvocardBusiness-sD2", "error");
                            }
                        } else {
                            showAlert(i18n.t('error.api.undefined') + "AdvocardBusiness-sD2", "error");
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    })
            },

            // send button pressed
            validate() {
                if (this.$refs.form.validate()) {
                    this.sendData();
                }
            },

            // Save to localStorage
            save() {
                let store = {
                    "policyNumber": this.policyNumber,
                    "name": this.name,
                    "issue": this.issue,
                    "consultationDate": this.consultationDate
                }
                localStorage.setItem('AdvocardBusiness', JSON.stringify(store));
            },
            saveDate() {
                this.save();
                this.consultationDateMenu = false;
            },

            // Clear localStorage
            clearRecoverData() {
                localStorage.setItem('AdvocardBusiness', "")
                this.dialog = false;
            },

            // Set localStorage to Formfields
            recoverData() {
                let recoverableData = localStorage.getItem('AdvocardBusiness');
                recoverableData = JSON.parse(recoverableData);
                this.policyNumber = recoverableData.policyNumber;
                this.name = recoverableData.name;
                this.issue = recoverableData.issue;
                this.consultationDate = recoverableData.consultationDate;
                this.dialog = false
            },

            //  if there is data to recover
            checkRecoverData() {
                let recoverableData = localStorage.getItem('AdvocardBusiness');
                if (recoverableData) {
                    this.dialog = true;
                }
            },

            // format Date
            formatDate(unformedDate){
                return dateFormatter(unformedDate);
            }

        },
        computed: {
            errorMessage() {
                return JSON.parse(JSON.stringify(this.$t('error.validation')));
            },
            inputLabels() {
                return JSON.parse(JSON.stringify(this.$t('insurance.AdvocardBusiness.inputField')));
            },
            policyNumberRules() {
                return [
                    v => /^\d+$/.test(v) || this.errorMessage["onlyNumbers"],
                    v => !!v || this.errorMessage.required,
                ]
            },
            nameRules() {
                return [
                    v => !!v || this.errorMessage.required,
                ]
            },
        },
        mounted() {
            this.checkRecoverData();
        }
    }
</script>

<style lang="stylus" scoped>
    .formBody
        margin-top 3vh
        width 100vw

    .form
        margin-top 1vh

</style>
<!-- Hide Number Button -->
<style>

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
</style>
